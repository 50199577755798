
/**
 * Icon Source for IconsBase: IconsHamburger-24x24
 * Size: 24x24
 * @category Icons
 * @subcategory Icons Source
 *
 * @component
 */
export default {
  name: 'IconHeaderHamburger-24x24',
};
